<template>
	<div>
		<user-strains-card
			v-for="(strainId, index) in strains.ids"
			:key="index"
			:strain="strains.items[strainId]"
			:type="type"
		/>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

export default {
	components: {
		UserStrainsCard: () => import('./StrainCard.vue')
	},
	data: function () {
		return {
			type: 'checkin'
		}
	},
	computed: {
		...mapGetters('user', [
			'strains'
		]),
		...mapGetters('auth', [
			'auth'
		])
	}
}
</script>
